<template>
  <div>
    <div
      v-if="recipesloaded"
      class="recipe-page scroll-top"
      @scroll="applyMask('recipe-page')"
    >
      <div class="d-flex flex-column align-items-center">
        <div class="recipe-page__title">
          <router-link to="/results" class="recipe-page__return">
            <img
              alt="return icon"
              src="../assets/svg/return.svg"
              v-svg-inline
            />
          </router-link>
          <div class="recipe-page__name">{{ recipe.name }}</div>
          <div
            class="recipe-page__favorite"
            :class="{ 'recipe-page__infavorite': isInFavorite(recipe) }"
            @click="toggleFavorite(recipe)"
          >
            <img alt="heart icon" src="../assets/svg/heart.svg" v-svg-inline />
          </div>
        </div>
        <div class="recipe-page__body">
          <div class="recipe-page__illustration-container">
            <img class="recipe-page__image" :src="recipe.image" />
            <ul class="recipe-page__component-list">
              <li
                class="component d-flex"
                v-for="component in recipe.components"
                :key="component"
              >
                <div
                  class="component__icon"
                  v-html="getIngredient(component.ingredient).icon_raw_svg"
                ></div>
                <div class="component__text">
                  {{ getComponentString(component) }}
                </div>
              </li>
            </ul>
          </div>
          <div
            class="recipe-page__instructions"
            v-html="recipe.instructions"
          ></div>
          <div class="share">
            <div class="share__description">Share recipe:</div>
            <a
              class="share__link"
              :href="geturl('facebook')"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="share__link-logo">
                <img
                  alt="facebook icon"
                  src="../assets/svg/facebook.svg"
                  v-svg-inline
                />
              </div>
              <div class="share__link-text">Facebook</div>
            </a>
            <a
              class="share__link"
              :href="geturl('twitter')"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="share__link-logo">
                <img
                  alt="twitter icon"
                  src="../assets/svg/twitter.svg"
                  v-svg-inline
                />
              </div>
              <div class="share__link-text">Twitter</div>
            </a>
            <a
              class="share__link"
              :href="geturl('whatsapp')"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="share__link-logo">
                <img
                  alt="whatsapp icon"
                  src="../assets/svg/whatsapp.svg"
                  v-svg-inline
                />
              </div>
              <div class="share__link-text">Whatsapp</div>
            </a>
            <a
              class="share__link"
              :href="geturl('mail')"
              target="_self"
              rel="noopener noreferrer"
            >
              <div class="share__link-logo">
                <img
                  alt="mail icon"
                  src="../assets/svg/mail.svg"
                  v-svg-inline
                />
              </div>
              <div class="share__link-text">E-mail</div>
            </a>
            <a class="share__link" @click="copyLink()">
              <div class="share__link-logo">
                <img
                  alt="link icon"
                  src="../assets/svg/link.svg"
                  v-svg-inline
                />
              </div>
              <div class="share__link-text">Copy link</div>
              <div class="share__link-tooltip">Copied</div>
            </a>
          </div>
        </div>
        <div class="recipe-page__stock">
          <StockBox />
        </div>
      </div>
    </div>
    <div
      v-else
      class="recipe-page__loader-container d-flex justify-content-center"
    >
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import StockBox from "@/components/StockBox.vue";
import recipeComponentMixin from "@/mixins/recipeComponent.js";
import applyMaskMixin from "@/mixins/utils/applyMask.js";

export default {
  name: "recipe-page",
  components: {
    StockBox,
  },
  mixins: [recipeComponentMixin, applyMaskMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    recipe() {
      return this.mainStore.recipes.find((element) => element.id == this.id);
    },
    recipesloaded() {
      return this.mainStore.recipesLoaded;
    },
  },
  methods: {
    getComponentString(component) {
      return (
        this.getQuantityString(component) +
        this.getNameString(component) +
        (component.optional ? " (optional)" : "")
      );
    },
    getQuantityString(component) {
      if (component.quantity === null) {
        return "";
      } else {
        return component.quantity + " ";
      }
    },
    copyLink() {
      navigator.clipboard.writeText(window.location.href);
      var tooltip = document
        .getElementsByClassName("share__link-tooltip")
        .item(0);
      //show tooltip for 0.5 seconds
      tooltip.style.display = "block";
      setTimeout(function () {
        tooltip.style.display = "none";
      }, 500);
    },
    geturl(source) {
      var url = window.location.href;
      var text = "Check out this great cocktail!";
      switch (source) {
        case "facebook":
          return "https://facebook.com/sharer/sharer.php?u=" + url;
        case "whatsapp":
          return "whatsapp://send?text=" + text + "%0A" + url;
        case "twitter":
          return "https://twitter.com/share?url=" + url + "&text=" + text;
        case "mail":
          return "mailto:?subject=" + text + "&body=" + url;
      }
    },
  },
  created() {
    var title = this.mainStore.recipes.find((element) => element.id == this.id);
    if (title != undefined) {
      document.title = title.name + " | Which Cocktail Tonight";
    }
  },
  updated() {
    var title = this.mainStore.recipes.find((element) => element.id == this.id)
      .name;
    document.title = title + " | Which Cocktail Tonight";
  },
};
</script>
